import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_BUSINESS_NAME, STR_BUSINESS_WAS_REGISTERED, STR_DASHBOARD, STR_INVALID_BUSINESS_NAME, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NO_BUSINESS_WARNING, STR_PLEASE_WAIT, STR_START_FREE_TRIAL, STR_SUCCESS, STR_TITLE_REGISTER_BUSINESS, STR_TODOS, STR_WATEJA } from "../Strings";
import { TiWarningOutline } from "react-icons/ti";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [loading, setLoading] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  async function registerBusiness() {
    if (!loading) {
      if (businessName.trim().length >= 0) {
        setLoading(true);
        await callApi("register-business", { businessName }).then(response => {
          if (response.status === 1) {
            if(appContext.doesUserHasBusiness()) {
              //just tell and go back
              appContext.tellMessage(STR_BUSINESS_WAS_REGISTERED[appContext.language]);
              appContext.navBack();
              appContext.getCurrentUserData();
            } else {
              appContext.tellMessage(STR_SUCCESS[appContext.language]);
              appContext.navTo({
                item: "crm",
              })
              appContext.refresh();
            }
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false)
      } else {
        appContext.tellError(STR_INVALID_BUSINESS_NAME[appContext.language])
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (

      <div className="container mw-700">
        <h6 className="mSectionTitle">{STR_TITLE_REGISTER_BUSINESS[appContext.language]}</h6>

        <div className="row">
          {
            (!appContext.doesUserHasBusiness()) ?
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body text-center bg-secondary">

                    <TiWarningOutline size={70} />

                    <h6
                      style={{
                        fontSize: "16px",
                        padding: "20px",
                      }}
                      className="font-semi-bold"
                    >
                      {STR_NO_BUSINESS_WARNING[appContext.language]}
                    </h6>

                  </div>
                </div>
              </div>
              : ""
          }

          <div className="col-md-12">
            <div className="mb-4 mt-4">
              <label className="form-label">{STR_BUSINESS_NAME[appContext.language]}</label>
              <input
                className="form-control"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>

            <div className="text-end">
              <button
                className="btn btn-padded btn-primary btn-rounded"
                onClick={registerBusiness}
              >
                {STR_START_FREE_TRIAL[appContext.language]}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}