import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_CALL, STR_CHANGE_PASSWORD, STR_CREATE_GOAL, STR_CREATE_TODO, STR_DASHBOARD, STR_EDIT, STR_MANAGE_ACTIVE_GOAL, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_REMOVE, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdBlock, MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdPassword, MdTask, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import { TbTarget } from "react-icons/tb";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [role, setRole] = useState(null);

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-user-data", { uid: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }


  function createGoal() {

  }

  function manageActiveGoal() {
    appContext.navTo({
      item: 'view',
      subItem: 'manage-active-user-goal',
      extraItem: data.uid,
    })
  }

  function getRole() {
    if (data) {
      const _businesses = JSON.parse(data.businesses);
      for (const _business of _businesses) {
        if (Number(_business.id) === Number(appContext.currentBusinessId)) {
          setRole(_business.role);
        }
      }
    }
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    getRole();
  }, [data])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6
          className="font-semi-bold mt-4"
          style={{
            fontSize: "24px",
            textTransform: "capitalize",
          }}
        >
          {data.name}
        </h6>
        <h6
          className="font-semi-bold text-muted"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.phone}
        </h6>

        <span
          className='badge bg-success'
        >
          {role}
        </span>


        <div className="row mt-4">

          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={manageActiveGoal}
              style={{
                height: "100%",
              }}
            >
              <TbTarget size={30} /><br />
              {STR_MANAGE_ACTIVE_GOAL[appContext.language]}
            </button>
          </div>



          <div className="col-4 col-md-3 mb-2 mt-2">
            <button
              className="btn btn-sm btn-block"
              onClick={createGoal}
              style={{
                height: "100%",
              }}
            >
              <MdBlock size={30} /><br />
              {STR_REMOVE[appContext.language]}
            </button>
          </div>



        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}